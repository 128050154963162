import { AdditionalFieldDelegate, AsyncPreviewComponent, buildCollection, EntityCallbacks } from "firecms";

import PriceTextPreview from "../custom_field_preview/PriceTextPreview";
import { SampleCollectionActions } from "../collection_actions/SampleCollectionActions";
import { SampleProductsView } from "../custom_entity_view/SampleProductsView";
import { Locale, Product } from "../types";
import { categories, currencies, locales, modalidades, turnos, verticals, channels, sedes, centros } from "./enums";
import CustomColorTextField from "../custom_field/CustomColorTextField";

export const localeCollection = buildCollection<Locale>({
  path: "locales",
  icon: "Translate",
  customId: locales,
  name: "Locales",
  singularName: "Locale",
  properties: {
    name: {
      name: "Name",
      validation: { required: true },
      dataType: "string"
    },
    description: {
      name: "Description",
      validation: { required: true },
      dataType: "string",
      multiline: true
    },
    selectable: {
      name: "Selectable",
      description: "Is this locale selectable",
      longDescription: "Changing this value triggers a cloud function that updates the parent product",
      dataType: "boolean"
    },
    video: {
      name: "Video",
      dataType: "string",
      validation: { required: false },
      storage: {
        storagePath: "videos",
        acceptedFiles: ["video/*"],
        fileName: (context) => {
          return context.file.name;
        }
      },
      columnWidth: 400
    }
  }
});

export const localeCollectionGroup = buildCollection({
  ...localeCollection,
  name: "Product locales group",
  description: "This is a collection group related to the locales subcollection of products",
  group: "Main",
  collectionGroup: true
})

const productAdditionalField: AdditionalFieldDelegate<Product> = {
  id: "spanish_title",
  name: "Spanish title",
  Builder: ({
    entity,
    context
  }) =>
    <AsyncPreviewComponent builder={
      context.dataSource.fetchEntity({
        path: `${entity.path}/${entity.id}/locales`,
        entityId: "es",
        collection: localeCollection
      }).then((entity) => entity?.values.name)
    } />
};

// price "300,99 €" => 300.99
const parsePrice = (price: string, locale: string) => {
  if (!price) return undefined;
  const priceStr = price.replace(/[^0-9,]/g, '');
  const priceNum = Number(priceStr.replace(',', '.'));
  if (isNaN(priceNum)) return undefined;
  return priceNum;
}

export const productCallbacks: EntityCallbacks<Product> = {
  onPreSave: ({
    collection,
    path,
    entityId,
    values,
    status
  }) => {
    // values.uppercase_name = values?.name?.toUpperCase();
    return values;
  },

  onSaveSuccess: (props) => {
    console.log("onSaveSuccess", props);
  },

  onDelete: (props) => {
    console.log("onDelete", props);
  },

  onPreDelete: (props) => {
    const email = props.context.authController.user?.email;
    if (!email || !email.endsWith("@camberi.com"))
      throw Error("Product deletion not allowed in this demo");
  },
  onFetch: ({ entity }) => {
    entity.values.slug = entity.values.slug || entity.values.id;
    entity.values.curso_anio = Number(entity.values.curso_anio) || undefined;
    entity.values.beca = parsePrice(String(entity.values.beca), 'es') || undefined;
    entity.values.amount = parsePrice(String(entity.values.amount), 'es') || undefined;
    entity.values.matricula = parsePrice(String(entity.values.matricula), 'es') || undefined;
    entity.values.descuento_matricula = parsePrice(String(entity.values.descuento_matricula), 'es') || undefined;
    entity.values.descuento = parsePrice(String(entity.values.descuento), 'es') || undefined;
    entity.values.mensualidad = parsePrice(String(entity.values.mensualidad), 'es') || undefined;
    entity.values.num_mensualidades = Number(entity.values.num_mensualidades) || undefined;
    entity.values.turno = entity.values.turno || "na";

    const sede = (entity.values.sede || '')
    const centro = (entity.values.centro || '')
    const vertical = (entity.values.vertical || '').toLowerCase();
    sedes[sede] = sede;
    centros[centro] = centro;
    verticals[vertical] = entity.values.vertical;
    return entity
  }
};

export const productsCollection = buildCollection<Product>({
  path: "products",
  alias: "ppp",
  callbacks: productCallbacks,
  name: "Products",
  singularName: "Product",
  group: "Main",
  icon: "ShoppingCart",
  description: "List of the products",
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: Boolean(authController.extra?.roles?.admin)
  }),
  Actions: SampleCollectionActions,
  properties: {
    slug: {
      dataType: "string",
      name: "Slug",
      description: "Id of this product",
      validation: {
        required: true
      }
    },
    vertical: {
      dataType: "string",
      name: "Vertical",
      description: "Vertical",
      clearable: true,
      validation: {
        required: true
      },
      enumValues: verticals
    },
    centro: {
      dataType: "string",
      name: "Centro",
      description: "Centro",
      clearable: true,
      validation: {
        required: false
      },
      enumValues: centros
    },
    sede: {
      dataType: "string",
      name: "Sede",
      description: "Sede",
      clearable: true,
      validation: {
        required: true
      },
      enumValues: sedes
    },
    modalidad: {
      dataType: "string",
      name: "Modalidad",
      description: "Modalidad",
      clearable: true,
      validation: {
        required: true
      },
      enumValues: modalidades
    },
    turno: {
      dataType: "string",
      name: "Turno",
      description: "Turno",
      clearable: true,
      validation: {
        required: false
      },
      enumValues: turnos
    },
    nombre_curso: {
      dataType: "string",
      name: "Nombre Curso",
      description: "Nombre Curso",
      clearable: true,
      validation: {
        required: true
      },
    },
    linea_negocio: {
      dataType: "string",
      name: "Linea Negocio",
      description: "Linea Negocio",
      clearable: true,
      validation: {
        required: false
      },
    },
    estudio: {
      dataType: "string",
      name: "Estudio",
      description: "Estudio",
      clearable: true,
      validation: {
        required: false
      },
    },
    amount: ({ values }) => ({
      dataType: "number",
      name: "Amount",
      validation: {
        requiredMessage: "You must set a price between 0 and 1000",
        min: 0,
        max: 10000
      },
      Preview: PriceTextPreview,
      description: "Price with range validation"
    }),
    matricula: ({ values }) => ({
      dataType: "number",
      name: "Matricula",
      validation: {
        requiredMessage: "You must set a price between 0 and 1000",
        min: 0,
        max: 10000
      },
      Preview: PriceTextPreview,
      description: "Price with range validation"
    }),
    descuento_matricula: ({ values }) => ({
      dataType: "number",
      name: "Descuento Matricula",
      validation: {
        requiredMessage: "You must set a price between 0 and 1000",
        min: 0,
        max: 10000
      },
      Preview: PriceTextPreview,
      description: "Price with range validation"
    }),
    descuento: ({ values }) => ({
      dataType: "number",
      name: "Descuento",
      validation: {
        requiredMessage: "You must set a price between 0 and 1000",
        min: 0,
        max: 10000
      },
      Preview: PriceTextPreview,
      description: "Price with range validation"
    }),
    mensualidad: ({ values }) => ({
      dataType: "number",
      name: "Mensualidad",
      validation: {
        requiredMessage: "You must set a price between 0 and 1000",
        min: 0,
        max: 10000
      },
      Preview: PriceTextPreview,
      description: "Price with range validation"
    }),
    num_mensualidades: {
      dataType: "number",
      name: "Num Mensualidades",
      validation: {
        requiredMessage: "You must set a price between 0 and 24",
        min: 0,
        max: 24
      },
    },
    curso_anio: { // 1,2,3,4,5,6
      dataType: "number",
      name: "Curso Anio",
      validation: {
        requiredMessage: "You must set a price between 0 and 10",
        min: 0,
        max: 10
      },
    },
    beca: { // 20%, 50%, 15%...
      dataType: "number",
      name: "Beca",
      validation: {
        requiredMessage: "You must set a price between 0 and 100",
        min: 0,
        max: 100
      },
    },
    pdf: {
      dataType: "string",
      name: "Pdf url",
      description: "The url of the pdf",
      validation: {
        required: false
      }
    },
    utm_channel: {
      dataType: "string",
      name: "Utm channel",
      description: "Utm channel sem,seo,all",
      validation: {
        required: false
      },
      enumValues: channels
    }
  }

});
export const productsSimpleCollection = buildCollection<any>({
  path: "products",
  name: "Products",
  singularName: "Product",
  group: "Main",
  icon: "ShoppingCart",
  properties: {
    name: {
      dataType: "string",
      name: "Name",
      description: "Name of this product",
      clearable: true,
      validation: {
        required: true
      }
    },
    category: {
      dataType: "string",
      name: "Category",
      clearable: true,
      enumValues: categories
    },
    price: {
      dataType: "number",
      name: "Price",
    },
    brand: {
      dataType: "string",
      name: "Brand",
      validation: {
        required: true
      }
    },
    description: {
      dataType: "string",
      name: "Description",
      description: "Example of a markdown field",
      markdown: true
    },
    metadata: {
      dataType: "map",
      name: "Metadata",
      description: "This is a field that allows arbitrary key-value input"

    },
    tags: {
      dataType: "array",
      name: "Tags",
      of: {
        dataType: "string"
      }
    },
    added_on: {
      dataType: "date",
      name: "Added on",
      autoValue: "on_create"
    }

  }

});
