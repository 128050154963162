import { EnumValues } from "firecms";

export const locales: EnumValues = {
  es: "Spanish",
  de: "German",
  en: "English",
  it: "Italian",
  fr: {
    id: "fr",
    label: "French",
    disabled: true
  }
};

export const currencies: EnumValues = [
  { id: "EUR", label: "Euros", color: "blueDark" },
  { id: "DOL", label: "Dollars", color: "greenLight" }
];

export const categories: EnumValues = {
  art_and_decoration: "Art and decoration",
  art_design_books: "Art and design books",
  babys: "Babies and kids",
  backpacks: "Backpacks and bags",
  bath: "Bath",
  bicycle: "Bicycle",
  books: "Books",
  cameras: "Cameras",
  clothing_man: "Clothing man",
  clothing_woman: "Clothing woman",
  coffee_and_tea: "Coffee and tea",
  cookbooks: "Cookbooks",
  delicatessen: "Delicatessen",
  desk_accessories: "Desk accessories",
  exercise_equipment: "Exercise equipment",
  furniture: "Furniture",
  gardening: "Gardening",
  headphones: "Headphones",
  home_accessories: "Home accessories",
  home_storage: "Home storage",
  kitchen: "Kitchen",
  lighting: "Lighting",
  music: "Music",
  outdoors: "Outdoors",
  personal_care: "Personal care",
  photography_books: "Photography books",
  serveware: "Serveware",
  smart_home: "Smart Home",
  sneakers: "Sneakers",
  speakers: "Speakers",
  sunglasses: "Sunglasses",
  toys_and_games: "Toys and games",
  watches: "Watches"
};



export const modalidades: EnumValues = {
  presencial: "Presencial",
  online: "Online",
  semipresencial: "Semipresencial",
  executive: "Executive",
  hibrida: "Hibrida",
}

export const turnos: EnumValues = {
  tarde: "tarde",
  mañana: "mañana",
  fin_de_semana: "fin_de_semana",
  viernes_sabado: "viernes_sabado",
  na: "na"
}

export const channels: EnumValues = {
  sem: "sem",
  seo: "seo",
  all: "all",
}

type StringAnyValue = {
  [key: string]: string;
};

export const sedes: StringAnyValue = {};
export const centros: StringAnyValue = {};
export const verticals: StringAnyValue = {};

