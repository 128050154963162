import React, { useCallback, useEffect } from "react";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";


import { getAnalytics, logEvent } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";

import { Authenticator, CMSView, FirebaseCMSApp, FirestoreIndexesBuilder } from "firecms";
// import { useDataEnhancementPlugin } from "@firecms/data_enhancement";

import { IconButton, Tooltip } from "@mui/material";
import { GitHub } from "@mui/icons-material";

import { firebaseConfig } from "../firebase_config";
import { ExampleCMSView } from "./ExampleCMSView";
import logo from "./images/metrodora.png";

import { textSearchController } from "./text_search";

import { customCollectionOverrideHandler } from "./collections/custom_collection_resolver";
import { CustomLoginView } from "./CustomLoginView";
import CustomColorTextField from "./custom_field/CustomColorTextField";
import { FirebaseApp } from "firebase/app";

import { productsCollection } from "./collections/products_collection";

function App() {
  const githubLink = (
    <Tooltip
      title="See this project on GitHub. This button is only present in this demo">
      <IconButton
        href={"https://github.com/FireCMSco/firecms"}
        rel="noopener noreferrer"
        target="_blank"
        component={"a"}
        size="large">
        <GitHub />
      </IconButton>
    </Tooltip>
  );

  const customViews: CMSView[] = [{
    path: "additional",
    name: "Additional",
    group: "Content",
    description: "This is an example of an additional view that is defined by the user",
    view: <ExampleCMSView />
  }];

  const onFirebaseInit = (config: object, app: FirebaseApp) => {
    // Just calling analytics enables screen tracking
    // getAnalytics(app);
  };

  const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({
    user,
    authController
  }) => {

    if (user?.email?.includes("flanders")) {
      throw Error("Stupid Flanders!");
    }

    const users = [
      {
        "email": "jprieto@metrodoraeducation.com",
        "roles": [
          "all"
        ]
      },
      {
        "email": "csantalo@metrodoraeducation.com",
        "roles": [
          "all"
        ]
      },
      {
        "email": "jcaballero@metrodoraeducation.com",
        "roles": [
          "cesif",
          "saius",
          "isep"
        ]
      },
      {
        "email": "lap@cesif.es",
        "roles": [
          "cesif"
        ]
      },
      {
        "email": "rmonteagudo@isep.es",
        "roles": [
          "isep",
          "saius"
        ]
      },
      {
        "email": "o.cativiela@fpoceano.com",
        "roles": [
          "oa"
        ]
      }
    ]

    // from user@fp.metrodoraeducation.com extract "fp.metrodoraeducation"
    const domain: string = user?.email?.split("@")[1].replace('.com', '') || 'admin';
    const centros: any = {
      keys: ["MetrodoraFP", "Cesif", "Saius", "Universanidad", "oa", "isep"].map(i => i.toLowerCase()),
      values: {
        "metrodorafp": "MetrodoraFP",
        "cesif": "Cesif",
        "saius": "Saius",
        "universanidad": "Universanidad",
        "oa": "oa",
        "isep": "isep"
      }
    }
    const verticals: any = {
      keys: ["es.metrodoraeducation", "fp.metrodoraeducation"].map(i => i.toLowerCase()),
      values: {
        "es.metrodoraeducation": "es",
        "fp.metrodoraeducation": "fp",
      }
    }
    if (centros.keys.includes(domain)) {
      productsCollection.forceFilter = {
        centro: ["==", centros.values[domain]]
      }
    }
    if (verticals.keys.includes(domain)) {
      productsCollection.forceFilter = {
        vertical: ["==", verticals.values[domain]]
      }
    }
    const currentEmail = user?.email
    if (users.find(user => user.email === currentEmail)) {
      // filter by roles
      const roles = (users.find(u => u.email === currentEmail) || {}).roles || [];
      const rolesMap = roles.filter(rol => centros.values[rol])
      if (rolesMap.length) productsCollection.forceFilter = {
        centro: ["in", roles.map(rol => centros.values[rol])]
      }
    }

    // This is an example of retrieving async data related to the user
    // and storing it in the controller's extra field
    const idTokenResult = await user?.getIdTokenResult();
    const userIsAdmin = idTokenResult?.claims.admin || currentEmail?.endsWith("@camberi.com");
    authController.setExtra({
      roles: {
        admin: userIsAdmin
      }
    });

    console.log("Allowing access to", user);
    return true;
  }, []);

  const collections = [
    productsCollection
  ];

  const onAnalyticsEvent = useCallback((event: string, data?: object) => {
    const analytics = getAnalytics();
    logEvent(analytics, event, data);
  }, []);

  // const dataEnhancementPlugin = useDataEnhancementPlugin({
  //   getConfigForPath: ({ path }) => {
  //     if (process.env.NODE_ENV !== "production")
  //       return true;
  //     if (path === "books")
  //       return true;
  //     return false;
  //   }
  // });

  // useEffect(() => {
  //   productsCollection.forceFilter = {
  //     centro: ["==", "MetrodoraFP"]
  //   }
  // }, []);

  return <FirebaseCMSApp
    name={"Metrodora"}
    // appCheckOptions={appCheckOptions}
    authentication={myAuthenticator}
    allowSkipLogin={false}
    plugins={[]}
    signInOptions={[
      "password",
      // "google.com"
      // 'anonymous',
      // 'phone',
      // 'facebook.com',
      // 'github.com',
      // 'twitter.com',
      // 'microsoft.com',
      // 'apple.com'
    ]}
    textSearchController={textSearchController}
    logo={logo}
    collections={(params) => collections}
    // views={customViews}
    collectionOverrideHandler={customCollectionOverrideHandler}
    firebaseConfig={firebaseConfig}
    onFirebaseInit={onFirebaseInit}
    toolbarExtraWidget={githubLink}
    LoginView={CustomLoginView}
  // onAnalyticsEvent={onAnalyticsEvent}
  // autoOpenDrawer={true}
  /*fields={{
    test_custom_field: {
      name: "Test custom field",
      dataType: "string",
      Field: CustomColorTextField
    }
  }}*/
  />;

}

export default App;
