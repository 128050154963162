import React from "react";
import { PropertyPreviewProps } from "firecms";
import Box from "@mui/material/Box";

export default function PriceTextPreview({
  value,
  property,
  size,
  customProps,
  entity
}: PropertyPreviewProps<number>) {

  // 300.99 => 300,99 €
  const price = (value: any) => {
    return `${value.toFixed(2).replace(".", ",")} €`;
  }
  return (
    <Box
      sx={{
        fontSize: value ? undefined : "small",
        color: value ? undefined : "#838383",
        fontWeight: "bold"
      }}>
      {price(value)}
    </Box>
  );

};
